// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-all-time-driver-stats-jsx": () => import("./../../../src/pages/all-time-driver-stats.jsx" /* webpackChunkName: "component---src-pages-all-time-driver-stats-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-circuits-jsx": () => import("./../../../src/pages/circuits.jsx" /* webpackChunkName: "component---src-pages-circuits-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-license-info-jsx": () => import("./../../../src/pages/license-info.jsx" /* webpackChunkName: "component---src-pages-license-info-jsx" */),
  "component---src-pages-races-jsx": () => import("./../../../src/pages/races.jsx" /* webpackChunkName: "component---src-pages-races-jsx" */),
  "component---src-templates-blog-page-template-jsx": () => import("./../../../src/templates/blog-page-template.jsx" /* webpackChunkName: "component---src-templates-blog-page-template-jsx" */),
  "component---src-templates-circuit-template-jsx": () => import("./../../../src/templates/circuit-template.jsx" /* webpackChunkName: "component---src-templates-circuit-template-jsx" */),
  "component---src-templates-race-template-jsx": () => import("./../../../src/templates/race-template.jsx" /* webpackChunkName: "component---src-templates-race-template-jsx" */),
  "component---src-templates-season-template-jsx": () => import("./../../../src/templates/season-template.jsx" /* webpackChunkName: "component---src-templates-season-template-jsx" */)
}

